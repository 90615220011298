import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <div
      style={{
        width: `100vw`,
        minHeight: `100vh`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        padding: `64px 32px`,
      }}
    >
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
